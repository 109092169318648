@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.data-plan-col {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  box-shadow: none;
}

.aditional-service-list {
  margin: 0px 6vw 0px 6vw;
}
